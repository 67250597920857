import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        t_az_1: '#aeddff',
        t_az_2: '#7bc6ff',
        t_az_3: '#1697f6',
        t_az_4: '#1867c0',
        t_branco: '#ffffff',
        t_preto: '#000000'
      }
    }
  }
})

export default vuetify
