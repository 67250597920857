import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/a_Home.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  // mode: 'hash',
  // linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          return resolve(savedPosition)
        }
        if (to.hash) {
          return resolve({
            selector: to.hash,
            offset: { x: 0, y: 0 }
          })
        }
        return resolve({ x: 0, y: 0 })
      }, 0)
    })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
