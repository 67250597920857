<template>
  <v-app>
    <v-app-bar app dark shaped color="#aeddff" elevation="2" inverted-scroll>
      <div class="d-flex align-center">
        <v-img
          alt="Elevare Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo_elevare_oficial_branca.svg')"
          transition="scale-transition"
          width="250"
        />
      </div>
      <v-spacer></v-spacer>

      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#inicio">
          <img src=".\..\public\img\criado\bar\1.gif" alt="Início" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>

      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#servicos_de_suporte">
          <img src=".\..\public\img\criado\bar\2.gif" alt="Serviços de Suporte" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>

      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#amostra">
          <img src=".\..\public\img\criado\bar\3.gif" alt="Amostra" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#sigilo_de_honorarios">
          <img src=".\..\public\img\criado\bar\4.gif" alt="Sigilo de Honorários" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#free">
          <img src=".\..\public\img\criado\bar\5.gif" alt="Free" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#equipe">
          <img src=".\..\public\img\criado\bar\6.gif" alt="Equipe" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#empresa">
          <img src=".\..\public\img\criado\bar\7.gif" alt="Empresa" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
      <v-btn color="#aeddff" elevation="0" width="0" height="48">
        <router-link to="/#fale">
          <img src=".\..\public\img\criado\bar\8.gif" alt="Fale" style="width: 48px; height: 48px"/>
        </router-link>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
